import React, { useEffect, useState } from "react";
import { Script } from "gatsby";
import { getParam } from "@utils";

const Userback = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const userback = getParam("userback");
    if (
      process.env.GATSBY_USERBACK_TOKEN &&
      (userback === "true" || process.env.GATSBY_SHOW_USERBACK)
    ) {
      window.Userback = window.Userback || {};
      window.Userback.access_token = process.env.GATSBY_USERBACK_TOKEN;
      setLoaded(true);
    }
  }, []);

  if (!loaded) return null;

  return <Script id="userback" src="https://static.userback.io/widget/v1.js" />;
};

export default Userback;
